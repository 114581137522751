.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
 /deep/ .form-container {
  min-width: 1080px !important;
}
.el-form {
  text-align: center;
  box-sizing: border-box;
}
.el-form /deep/ .el-form-item {
  width: 470px;
  margin-right: 50px;
}
.el-form /deep/ .el-form-item:nth-child(2n) {
  margin-right: 0;
}
.el-form /deep/ .el-form-item .el-cascader {
  display: block;
}
.el-form /deep/ .el-form-item .el-select {
  width: 100%;
}
.el-form .learn {
  margin-bottom: 1.0416666666666665vw;
  width: 980px;
}
.el-form .learn-item {
  margin-left: 4.166666666666666vw;
}
